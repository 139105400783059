import { ApiClient } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class AuthService {
    constructor(http) {
        this.http = http;
        this.token$ = new BehaviorSubject(null);
        this.oauthUrl = '/oauth/token';
    }
    login(email, password) {
        return this.http.postGuest('auth/login', { email: email, password: password });
    }
    saveAuth(auth) {
        try {
            localStorage.setItem('token', JSON.stringify(auth));
        }
        catch (e) {
        }
    }
    getToken() {
        return new Observable(subscriber => {
            try {
                const token = localStorage.getItem('token');
                subscriber.next(token ? JSON.parse(token) : null);
                subscriber.complete();
            }
            catch (e) {
                subscriber.error({
                    error: 'Si è verificato un errore nella lettura del token in sessione'
                });
            }
        });
    }
    cleanAuth() {
        try {
            localStorage.removeItem('token');
        }
        catch (e) {
        }
    }
    saveToken(data) {
        try {
            localStorage.setItem('token', JSON.stringify(data));
        }
        catch (e) {
        }
    }
    forgotPassword(data) {
        return this.http.postGuest('auth/forgot-password', data);
    }
    resetPassword(data) {
        return this.http.postGuest('auth/reset-password', data);
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.ApiClient)); }, token: AuthService, providedIn: "root" });
