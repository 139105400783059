import {Pipe, PipeTransform} from '@angular/core';
import {EventStatus} from "../../models/Event";

@Pipe({
  name: 'statoLabel',
  pure: false
})

export class StatoLabelPipe implements PipeTransform {
  transform(stato: string): string {

    return EventStatus[stato];

  }
}
