import {Injectable} from '@angular/core';
import {ApiClient} from '../../core/services/api.service';
import {Observable} from 'rxjs';
import {Event} from '../../models/Event';

@Injectable({
  providedIn: 'root',
})

export class FeatureService {

  constructor(
    private http: ApiClient
  ) {
  }

  calendar({date, type, escludiTrattamento = null}): Observable<Event[]> {

    // @ts-ignore
    return this.http.get(`events?date=${date}&type=${type}${escludiTrattamento ? '&escludiTrattamento=' + escludiTrattamento : ''}`);

  }

  searchClient({query}) {

    return this.http.get(`events?query=${query}`);

  }

  createEvent(formData) {

    return this.http.post(`events`, formData);

  }

  updateEvent(id, formData) {

    formData = Object.assign({}, formData, {'_method': 'PUT'})
    return this.http.post(`events/${id}`, formData);

  }

  deleteEvent(id, formData) {

    formData = Object.assign({}, formData, {'_method': 'DELETE'})
    return this.http.post(`events/${id}`, formData);

  }

  dashboard() {

    return this.http.get('dashboard');

  }

  appuntamentiNonPianificati() {

    return this.http.get('dashboard/appuntamenti-non-pianificati');

  }

  senzaAppuntamento() {

    return this.http.get('dashboard/senza-appuntamento');

  }

  checkupNonPianificati() {

    return this.http.get('checkup/pending');

  }

  nuovoCheckup(data) {

    return this.http.post('dashboard/nuovo-checkup', data);


  }

  getCalendarUrl() {

    return this.http.get('account/calendar-url');

  }

  getGoogleConnectionUrl() {

    return this.http.getEndpoint('calendar/connect');

  }

  getGoogleDisconnectionUrl() {

    return this.http.getEndpoint('calendar/disconnect');

  }

  getTrattamentiAttivi(categoria) {

    return this.http.get(`dashboard/${categoria}`);

  }

  sendConfirmRequest({ type, id }) {

    return this.http.post('events/send-confirm-request', { type, id })

  }
}
