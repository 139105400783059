import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {BrowserTracing} from '@sentry/tracing/dist/browser';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from '@sentry/angular';

Sentry.init({
  environment: environment.name,
  dsn: 'https://63eebc85d4984c578883ab3662e94eb7@o1045695.ingest.sentry.io/6527763',
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['localhost', environment.API_ENDPOINT],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
