

    <div class="wrapper">
      <div class="wrapper_calendar">
        <h3>{{ (viewDate$ | async | dateFormat: "EEEE dd MMMM yyyy") | titlecase }}</h3>

        <mwl-calendar-day-view
          [viewDate]="viewDate$ | async"
          [events]="events"
          [dayStartHour]="8"
          [dayEndHour]="20"
          [hourSegmentHeight]="80"
          [hourSegments]="1"
          [tooltipAppendToBody]="false"
          (hourSegmentClicked)="handleSegmentClick($event)"
        >
        </mwl-calendar-day-view>
      </div>
      <div class="wrapper_form">
        <div class="modal-header">
          <div class="d-flex align-items-center justify-content-between" style="width: 100%;">
            <h4 class="modal-title"><strong>Data appuntamento</strong></h4>
          </div>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (submit)="handleSubmit()">
            <p>Seleziona la data dell'appuntamento:</p>
            <app-datetime-picker formControlName="data"></app-datetime-picker>

            <div *ngIf="note" class="mt-3 mb-3">Note: {{ note }}</div>

            <div *ngIf="pianificaFuturi">
              <label class="checkbox checkbox-success mt-5">
                <input type="checkbox" formControlName="calcola">
                <span></span>Calcola automaticamente le date dei prossimi appuntamenti.<br />
                <small style="display: block; margin-top: 5px;">Ricorda che il calcolo potrebbe coincidere con giorni di chiusura non ancora programmati o festività. Controllale sempre prima di confermarle al cliente.</small>
              </label>
            </div>

            <!--
            <div *ngIf="showConfirm">
              <label class="checkbox checkbox-success mt-5">
                <input type="checkbox" formControlName="confermato" value="1">
                <span></span>L'appuntamento <i class="text-success" style="font-style: normal">è già stato confermato</i>. Non serve inviare l'sms di richiesta conferma, basta quello di promemoria<br />
              </label>
            </div>
            -->
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-default" (click)="modal.dismiss()">Chiudi</button>
          <button [disabled]="form.invalid" class="btn btn-primary" (click)="handleSubmit()">Conferma</button>
        </div>
      </div>
    </div>

  