import {Injectable} from '@angular/core';
import {Token} from '../../models/token';
import {ApiClient} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  token: any;
  error: any;

  private oauthUrl = '/oauth/token';

  constructor(private http: ApiClient,) {
  }

  login(email: string, password: string) {
    return this.http.postGuest('auth/login', {email: email, password: password});
  }

  saveAuth(auth) {
    try {
      localStorage.setItem('token', JSON.stringify(auth));
    } catch (e) {
    }
  }

  getToken() {

    return new Observable(subscriber => {
      try {
        const token = localStorage.getItem('token');
        subscriber.next(token ? JSON.parse(token) : null);
        subscriber.complete();
      } catch (e) {
        subscriber.error({
          error: 'Si è verificato un errore nella lettura del token in sessione'
        });
      }
    });

  }

  cleanAuth() {
    try {
      localStorage.removeItem('token');
    } catch (e) {
    }
  }

  saveToken(data: Token) {
    try {
      localStorage.setItem('token', JSON.stringify(data));
    } catch (e) {
    }
  }


  forgotPassword(data) {

    return this.http.postGuest('auth/forgot-password', data)

  }

  resetPassword(data) {

    return this.http.postGuest('auth/reset-password', data);

  }

}
