import {CalendarEvent, CalendarEventTitleFormatter} from 'angular-calendar';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CustomEventTitleFormatterProvider extends CalendarEventTitleFormatter {

  week(event: CalendarEvent, title: string): string {
    if (!event.allDay)
      return event.meta.type === 'chiusura' ? 'CHIUSURA' : `${moment(event.start).format('HH:mm')} - ${event.meta && event.meta.hasOwnProperty('cliente') && event.meta.cliente ? event.meta.cliente + ':' : ''} ${title} ${event.meta && event.meta.note ? '(Note: ' + event.meta.note + ')' : ''}` + (event.meta.confirm_request_sent_at ? '<span class="fa fa-sms"></span>' : '');
    return title;
  }

  day(event: CalendarEvent, title: string): string {
    if (!event.allDay)
      return event.meta.type === 'chiusura' ? 'CHIUSURA' : `${moment(event.start).format('HH:mm')} - ${event.meta && event.meta.hasOwnProperty('cliente') && event.meta.cliente ? event.meta.cliente + ':' : ''} ${title} ${event.meta && event.meta.note ? '(Note: ' + event.meta.note + ')' : ''}` + (event.meta.confirm_request_sent_at ? '<span class="fa fa-sms"></span>' : '');
    return title;
  }

}
