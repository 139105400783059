import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { AppProvider } from './core/providers/app.provider';
import localeItIt from '@angular/common/locales/it';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as moment from 'moment';
import * as Sentry from '@sentry/angular';
registerLocaleData(localeItIt);
moment.locale('it');
export function appProviderFactory(provider) {
    return () => provider.load();
}
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
const ɵ0 = Sentry.createErrorHandler({ showDialog: false });
export class AppModule {
}
export { ɵ0 };
